<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
            <h4 class="mb-sm-0">Laporan Laba Rugi</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Daftar Laporan Laba Rugi</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Daftar Laporan Laba Rugi</h4>
              <router-link :to="{ name: 'income.statement.form' }" type="button"
                class="btn btn-primary waves-effect waves-light ms-2" v-if="permission.can('income_statement create')">
                <i class="bx bx-plus font-size-16 align-middle me-2"></i>
                Laporan Laba Rugi
              </router-link>
            </div>

            <div class="card-body">
              <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions" :reload="reloadDataTable" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/datatable/DataTable.vue";
import { endpoint } from "../../host";
import moment from "moment";
import permissonService from "../../services/permisson-service";
import globalService from '../../services/global-service';

export default {
  components: {
    DataTable,
  },

  created() {
    if (this.permission.can('income_statement edit')) {
      this.actions.push(
        {
          icon: "bx bx-edit",
          color: "btn-warning",
          tooltip: "edit",
          onclick: (data) => {
            this.$router.push({
              name: 'income.statement.form',
              params: data,
            });
          },
        });
    }
  },

  data() {
    return {
      permission: permissonService,
      reloadDataTable: false,
      dataTableUrl: endpoint['income.statement'],
      actions: [
      ],
      columnHeaders: [
        { name: "year", text: "Tahun" },
        {
          text: "Bulan", render: (data) => {
            return moment().month(data.month).format('MMMM');
          }
        },
        {
          text: "Total Pendapatan",
          render: (data) => {
            return globalService.toIdr(data.total_revenue);
          },
        },
        {
          text: "Total Beban",
          render: (data) => {
            return globalService.toIdr(data.total_expanses);
          },
        },
        {
          text: "Laba Bersih",
          render: (data) => {
            return globalService.toIdr(data.net_income);
          },
        },
      ],
    };
  },
  methods: {
  },
};
</script>

<style>
</style>